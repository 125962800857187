<template>
  <!-- Padrão de uso -->
  <!-- <DXATextField Field="Título" :Text="text" :ErrorText="error" :IsCurrency="false" @update="(value) => { text = value;}" @text_changed="validate_text" /> -->
  <div :style="`height: ${FullHeight ? '80%' : 'auto'}`">
    <p id="label" class="dxa-text-field-label mb-1" :style="`color: ${color}`">
      {{ Field }}
    </p>
    <div :style="`position: relative; height: ${FullHeight ? '80%' : 'auto'}`">
      <money
        v-if="IsCurrency"
        :prefix="getCurrencyPrefixData(Currency)"
        :disabled="Disabled"
        thousand="."
        thousands="."
        decimal=","
        type="text"
        class="dxa-text-field"
        :style="`color: ${
          color == 'red' ? 'red' : Disabled ? '#9C9C9C' : 'var(--dark)'
        }`"
        @focus="field_selected"
        @blur="field_unselected"
        v-model="text"
      ></money>
      <input
        v-else-if="IsDate"
        :disabled="Disabled"
        placeholder="DD/MM/YYYY"
        v-mask="'##/##/####'"
        type="text"
        class="dxa-text-field"
        :style="`color: ${
          color == 'red' ? 'red' : Disabled ? '#9C9C9C' : 'var(--dark)'
        }`"
        @focus="field_selected"
        @blur="field_unselected"
        v-model="text"
      />
      <textarea
        v-else-if="FullHeight"
        :disabled="Disabled"
        type="text"
        v-model="text"
        :style="`color: ${
          color == 'red' ? 'red' : Disabled ? '#9C9C9C' : 'var(--dark)'
        }`"
        rows="5"
        class="dxa-textarea dxa-text-field"
      ></textarea>
      <input
        v-else-if="Mask"
        :disabled="Disabled"
        :type="Type ? Type : 'text'"
        class="dxa-text-field"
        v-mask="Mask"
        :style="`color: ${
          color == 'red' ? 'red' : Disabled ? '#9C9C9C' : 'var(--dark)'
        }`"
        @focus="field_selected"
        @blur="field_unselected"
        v-model="text"
      />
      <div class="input-with-btn" v-else-if="Type == 'password'">
        <input
          :type="showPassword ? 'text' : 'password'"
          class="dxa-text-field"
          :style="`color: ${
            color == 'red' ? 'red' : Disabled ? '#9C9C9C' : 'var(--dark)'
          }`"
          @focus="field_selected"
          @blur="field_unselected"
          v-model="text"
        />
        <v-icon size="20" color="grey" @click="showPassword = !showPassword">{{
          showPassword ? "mdi-eye" : "mdi-eye-off"
        }}</v-icon>
      </div>
      <input
        v-else
        :disabled="Disabled"
        :type="Type ? Type : 'text'"
        :min="Min"
        :max="Max"
        class="dxa-text-field"
        :style="`color: ${
          color == 'red' ? 'red' : Disabled ? '#9C9C9C' : 'var(--dark)'
        }`"
        @focus="field_selected"
        @blur="field_unselected"
        v-model="text"
      />
      <span v-if="ErrorText && !typing" class="error-msg">{{
        $t(ErrorText)
      }}</span>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import { getCurrencyPrefix } from "@/shared/helpers/currencyHelper";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";
export default {
  name: "DXATextFieldComponent",
  components: {},
  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    color: "#9C9C9C",
    typing: false,
    showPassword: false,
    getCurrencyPrefixData: getCurrencyPrefix,
  }),
  async created() {},
  props: {
    Text: String,
    Field: String,
    IsCurrency: Boolean,
    IsDate: Boolean,
    ErrorText: String,
    FullHeight: Boolean,
    Disabled: Boolean,
    Type: String,
    Mask: String,
    Min: Number,
    Max: Number,
    Currency: { type: Number, default: CurrencyEnum.BRL },
  },
  computed: {
    text: {
      get() {
        return this.Text;
      },
      set(value) {
        this.$emit("update", value);
      },
    },
  },
  watch: {
    text() {
      this.$emit("text_changed");
    },
    ErrorText() {
      if (this.ErrorText) {
        this.color = "red";
      } else {
        this.color = "#1A7DFF";
      }
    },
  },
  methods: {
    field_selected() {
      this.color = "#01D181";
      this.typing = true;
    },
    field_unselected() {
      this.typing = false;
      if (this.ErrorText) {
        this.color = "red";
      } else {
        this.color = "#1A7DFF";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-with-btn {
  background: #e9e9e9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-right: 12px;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
.dxa-text-field-label {
  color: #9c9c9c;
}
.error-msg {
  color: red;
  right: 0px;
  position: absolute;
  top: 34px;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.15px;
}
</style>
