import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"dxa_modal"},[_c(VCardText,{staticClass:"pa-3 pt-2"},[(_vm.allocation.Finalized && _vm.edit)?_c('div',[_c(VRow,{attrs:{"align":"center"}},[_c('p',{staticClass:"small-field-text"},[_vm._v(_vm._s(_vm.$t("deposit"))+":")]),_c('p',{staticClass:"value-text ml-2"},[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.allocation.Currency, _vm.allocation.DepositedValue))+" ")])]),_c(VRow,{staticClass:"mt-4",attrs:{"align":"center"}},[_c('p',{staticClass:"mb-0 small-field-text"},[_vm._v(_vm._s(_vm.$t("stock_type")))]),_c('div',{staticClass:"ml-2",staticStyle:{"min-width":"50%"}},[_c(VSelect,{attrs:{"dense":"","hide-details":"","items":_vm.stocks,"return-object":"","filled":"","item-text":"stockTypeName","item-value":"value"},on:{"change":_vm.changeAllocationStockQuantity},model:{value:(_vm.allocation.Stock),callback:function ($$v) {_vm.$set(_vm.allocation, "Stock", $$v)},expression:"allocation.Stock"}}),(_vm.allocation.Stock)?_c('p',{staticClass:"mb-0",staticStyle:{"font-style":"italic","line-height":"1.5","font-size":"12px"}},[_vm._v(" *"+_vm._s(_vm.$t("stock_value", { value: _vm.formatCurrency( _vm.allocation.Currency, _vm.allocation.Stock.value, 2, 5 ), }))+" ")]):_vm._e()],1)]),_c(VRow,{staticClass:"mt-4",attrs:{"align":"center"}},[_c('p',{staticClass:"mb-0 small-field-text"},[_vm._v(_vm._s(_vm.$t("stock_quantity")))]),_c('DXATextField',{staticClass:"ml-2",staticStyle:{"min-width":"50%"},attrs:{"Text":_vm.allocation.StockQuantity,"ErrorText":_vm.errorStocks},on:{"update":function (value) {
              _vm.allocation.StockQuantity = value;
            },"text_changed":_vm.validate_stock_quantity}})],1)],1):_c('div',[_c(VRow,{attrs:{"align":"center","no-gutters":""}},[_c('div',[_c('p',{staticStyle:{"font-weight":"bold","font-size":"18px","line-height":"23px","letter-spacing":"0.15px","color":"#9c9c9c","margin-bottom":"0px !important"}},[_vm._v(" "+_vm._s(_vm.$t("request_date"))+" ")]),_c('p',{staticStyle:{"font-weight":"300","font-size":"16px","line-height":"20px","letter-spacing":"0.15px","color":"var(--dark)"}},[_vm._v(" "+_vm._s(_vm.gs.convert_date(_vm.allocation.Timestamp, false))+" ")])]),_c(VSpacer),(!_vm.allocation.Finalized)?_c(VBtn,{staticClass:"ma-0",class:_vm.edit ? 'dxa-btn-primary' : 'dxa-btn-secondary',on:{"click":function($event){_vm.edit = !_vm.edit}}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-pencil")]),_vm._v(_vm._s(_vm.$t("edit")))],1):_vm._e()],1),_c(VDivider,{staticClass:"my-2"}),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"small-field-text"},[_vm._v(_vm._s(_vm.$t("signed_docs"))+":")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCheckbox,{staticClass:"dxa-checkbox",attrs:{"readonly":!_vm.edit,"color":"primary","label":_vm.$t('investment_proxy')},model:{value:(_vm.allocation.SignedInvestmentProxy),callback:function ($$v) {_vm.$set(_vm.allocation, "SignedInvestmentProxy", $$v)},expression:"allocation.SignedInvestmentProxy"}})],1),(
            _vm.allocation.InvestmentVehicle.ClosingType ==
            _vm.vehicleClosingOptionsEnum.CLOSING_SA
          )?_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCheckbox,{staticClass:"dxa-checkbox",attrs:{"readonly":!_vm.edit,"color":"primary","label":_vm.$tc('adhesion_term', 1)},model:{value:(_vm.allocation.SignedTerm),callback:function ($$v) {_vm.$set(_vm.allocation, "SignedTerm", $$v)},expression:"allocation.SignedTerm"}})],1):_vm._e(),(
            _vm.allocation.InvestmentVehicle.ClosingType ==
            _vm.vehicleClosingOptionsEnum.CLOSING_SA
          )?_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCheckbox,{staticClass:"dxa-checkbox",attrs:{"readonly":!_vm.edit,"color":"primary","label":_vm.$t('newsletter_subscription')},model:{value:(_vm.allocation.SignedNewsletterSubscription),callback:function ($$v) {_vm.$set(_vm.allocation, "SignedNewsletterSubscription", $$v)},expression:"allocation.SignedNewsletterSubscription"}})],1):_vm._e(),(
            _vm.allocation.InvestmentVehicle.ClosingType ==
            _vm.vehicleClosingOptionsEnum.CLOSING_LLC
          )?_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCheckbox,{staticClass:"dxa-checkbox",attrs:{"readonly":!_vm.edit,"color":"primary","label":_vm.$t('subscription_agreement')},model:{value:(_vm.allocation.SignedSubscriptionAgreement),callback:function ($$v) {_vm.$set(_vm.allocation, "SignedSubscriptionAgreement", $$v)},expression:"allocation.SignedSubscriptionAgreement"}})],1):_vm._e()],1),_c(VDivider,{staticClass:"my-2"}),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"small-field-text"},[_vm._v(_vm._s(_vm.$t("deposit"))+":")]),(!_vm.edit)?_c('p',{staticClass:"value-text mt-1"},[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.allocation.Currency, _vm.allocation.DepositedValue))+" ")]):_c('DXATextField',{attrs:{"Text":_vm.allocation.DepositedValue,"IsCurrency":true,"Currency":_vm.allocation.Currency},on:{"update":function (value) {
                _vm.allocation.DepositedValue = value;
              }}}),_c('p',{staticClass:"small-field-text mt-3"},[_vm._v(_vm._s(_vm.$t("documents_date"))+":")]),(
              _vm.allocation.InvestmentVehicle.ClosingType ==
              _vm.vehicleClosingOptionsEnum.CLOSING_SA
            )?_c('div',{staticClass:"mt-3",staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticClass:"document-name"},[_vm._v(_vm._s(_vm.$tc("adhesion_term", 1))+":")]),(!_vm.edit)?_c('p',{staticClass:"document-date"},[_vm._v(" "+_vm._s(_vm.gs.convert_date(_vm.allocation.TermSend, false))+" ")]):_c('DXATextField',{staticClass:"ml-2",attrs:{"Text":_vm.dateTerm,"IsDate":true,"ErrorText":_vm.errorTerm},on:{"update":function (value) {
                  _vm.dateTerm = value;
                  var returnValues = _vm.check_date(_vm.dateTerm);
                  _vm.allocation.TermSend = returnValues[0];
                  _vm.errorTerm = returnValues[1];
                }}})],1):_vm._e(),(
              _vm.allocation.InvestmentVehicle.ClosingType ==
              _vm.vehicleClosingOptionsEnum.CLOSING_SA
            )?_c('div',{staticClass:"mt-3",staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticClass:"document-name"},[_vm._v(_vm._s(_vm.$t("newsletter_subscription"))+":")]),(!_vm.edit)?_c('p',{staticClass:"document-date"},[_vm._v(" "+_vm._s(_vm.gs.convert_date(_vm.allocation.NewsletterSubscriptionSend, false))+" ")]):_c('DXATextField',{staticClass:"ml-2",attrs:{"Text":_vm.dateNewsletter,"IsDate":true,"ErrorText":_vm.errorNewsletter},on:{"update":function (value) {
                  _vm.dateNewsletter = value;
                  var returnValues = _vm.check_date(_vm.dateNewsletter);
                  _vm.allocation.NewsletterSubscriptionSend = returnValues[0];
                  _vm.errorNewsletter = returnValues[1];
                }}})],1):_vm._e(),(
              _vm.allocation.InvestmentVehicle.ClosingType ==
              _vm.vehicleClosingOptionsEnum.CLOSING_LLC
            )?_c('div',{staticClass:"mt-3",staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticClass:"document-name"},[_vm._v(_vm._s(_vm.$t("subscription_agreement"))+":")]),(!_vm.edit)?_c('p',{staticClass:"document-date"},[_vm._v(" "+_vm._s(_vm.gs.convert_date(_vm.allocation.SubscriptionAgreementSend, false))+" ")]):_c('DXATextField',{staticClass:"ml-2",attrs:{"Text":_vm.dateAgreement,"IsDate":true,"ErrorText":_vm.errorNewsletter},on:{"update":function (value) {
                  _vm.dateAgreement = value;
                  var returnValues = _vm.check_date(_vm.dateAgreement);
                  _vm.allocation.SubscriptionAgreementSend = returnValues[0];
                  _vm.errorNewsletter = returnValues[1];
                }}})],1):_vm._e()],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCol,[_c('div',{staticClass:"mt-3",staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticClass:"document-name"},[_vm._v(_vm._s(_vm.$t("deposited_date"))+":")]),(!_vm.edit)?_c('p',{staticClass:"document-date"},[_vm._v(" "+_vm._s(_vm.gs.convert_date(_vm.allocation.DepositedDate, false))+" ")]):_c('DXATextField',{staticClass:"ml-2",attrs:{"Text":_vm.depositedDate,"IsDate":true,"ErrorText":_vm.errorDepositedDate},on:{"update":function (value) {
                    _vm.depositedDate = value;
                    var returnValues = _vm.check_date(_vm.depositedDate);
                    _vm.allocation.DepositedDate = returnValues[0];
                    _vm.errorDepositedDate = returnValues[1];
                  }}})],1)]),_c(VCol,[(!_vm.edit)?_c('div',[_c('p',{staticClass:"small-field-text mt-3"},[_vm._v(_vm._s(_vm.$t("obs")))]),_c('div',{staticStyle:{"background":"#e9e9e9","border-radius":"5px","height":"140px","overflow-y":"auto"}},[_c('p',{staticClass:"observations-text pa-3"},[_vm._v(" "+_vm._s(_vm.allocation.Observations)+" ")])])]):_c('DXATextField',{attrs:{"Field":_vm.$t('obs'),"Text":_vm.allocation.Observations,"FullHeight":true},on:{"update":function (value) {
                  _vm.allocation.Observations = value;
                }}})],1)],1)],1),_c(VDivider,{staticClass:"mt-2"}),_c('div',{staticClass:"mt-2",staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticClass:"small-field-text"},[_vm._v(_vm._s(_vm.$t("last_follow_up_date"))+":")]),(!_vm.edit)?_c('p',{staticClass:"follow-up-date"},[_vm._v(" "+_vm._s(_vm.gs.convert_date(_vm.allocation.LastFollowUp, false))+" ")]):_c('DXATextField',{staticClass:"ml-2",attrs:{"IsDate":true,"Text":_vm.dateFollowUp,"ErrorText":_vm.errorFollowUp},on:{"update":function (value) {
              _vm.dateFollowUp = value;
              var returnValues = _vm.check_date(_vm.dateFollowUp);
              _vm.allocation.LastFollowUp = returnValues[0];
              _vm.errorFollowUp = returnValues[1];
            }}})],1)],1),_c(VCardActions,{staticClass:"mt-4",staticStyle:{"display":"flex","justify-content":"center"}},[(!_vm.edit)?_c(VBtn,{staticClass:"dxa-btn-secondary",attrs:{"min-width":"100","width":"50%"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" ")]):_c(VRow,[_c(VCol,{staticClass:"pa-2",attrs:{"cols":"6","md":"3"}},[_c(VBtn,{staticClass:"dxa-btn-secondary mx-0",attrs:{"width":"100%"},on:{"click":function($event){return _vm.cancel_click()}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1),_c(VCol,{staticClass:"pa-2",attrs:{"cols":"6","md":"3"}},[(!_vm.allocation.Finalized)?_c(VBtn,{staticClass:"dxa-btn-secondary mx-0",attrs:{"width":"100%","loading":_vm.loading},on:{"click":function($event){return _vm.submit(false)}}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")]):_vm._e()],1),_c(VCol,{staticClass:"pa-2",attrs:{"cols":"12","md":"6"}},[_c(VBtn,{staticClass:"dxa-btn-primary mx-0",attrs:{"width":"100%","disabled":_vm.finalize_btn_disabled(),"loading":_vm.loading},on:{"click":function($event){_vm.allocation.Finalized
                ? _vm.submit(true)
                : (_vm.allocation.Finalized = true)}}},[_vm._v(_vm._s(_vm.$t("finalize")))]),(_vm.stocks.length == 0)?_c('p',{staticClass:"mb-0",staticStyle:{"font-style":"italic","color":"red","line-height":"1.5","font-size":"12px"}},[_vm._v(" *"+_vm._s(_vm.$t("have_no_stocks"))+" ")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }