<template>
  <v-card class="dxa_modal">
    <v-card-text class="pa-3 pt-2">
      <div v-if="allocation.Finalized && edit">
        <v-row align="center">
          <p class="small-field-text">{{ $t("deposit") }}:</p>
          <p class="value-text ml-2">
            {{ formatCurrency(allocation.Currency, allocation.DepositedValue) }}
          </p>
        </v-row>
        <v-row align="center" class="mt-4">
          <p class="mb-0 small-field-text">{{ $t("stock_type") }}</p>
          <div class="ml-2" style="min-width: 50%">
            <v-select
              dense
              hide-details
              v-model="allocation.Stock"
              @change="changeAllocationStockQuantity"
              :items="stocks"
              return-object
              filled
              item-text="stockTypeName"
              item-value="value"
            />
            <p
              v-if="allocation.Stock"
              style="font-style: italic; line-height: 1.5; font-size: 12px"
              class="mb-0"
            >
              *{{
                $t("stock_value", {
                  value: formatCurrency(
                    allocation.Currency,
                    allocation.Stock.value,
                    2,
                    5
                  ),
                })
              }}
            </p>
          </div>
        </v-row>
        <v-row align="center" class="mt-4">
          <p class="mb-0 small-field-text">{{ $t("stock_quantity") }}</p>
          <DXATextField
            class="ml-2"
            style="min-width: 50%"
            :Text="allocation.StockQuantity"
            @update="
              (value) => {
                allocation.StockQuantity = value;
              }
            "
            @text_changed="validate_stock_quantity"
            :ErrorText="errorStocks"
          />
        </v-row>
      </div>

      <div v-else>
        <v-row align="center" no-gutters>
          <div>
            <p
              style="
                font-weight: bold;
                font-size: 18px;
                line-height: 23px;
                letter-spacing: 0.15px;
                color: #9c9c9c;
                margin-bottom: 0px !important;
              "
            >
              {{ $t("request_date") }}
            </p>
            <p
              style="
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.15px;
                color: var(--dark);
              "
            >
              {{ gs.convert_date(allocation.Timestamp, false) }}
            </p>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!allocation.Finalized"
            :class="edit ? 'dxa-btn-primary' : 'dxa-btn-secondary'"
            class="ma-0"
            @click="edit = !edit"
            ><v-icon class="mr-1">mdi-pencil</v-icon>{{ $t("edit") }}</v-btn
          >
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row>
          <v-col cols="12" md="6">
            <p class="small-field-text">{{ $t("signed_docs") }}:</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-checkbox
              class="dxa-checkbox"
              :readonly="!edit"
              v-model="allocation.SignedInvestmentProxy"
              color="primary"
              :label="$t('investment_proxy')"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-if="
              allocation.InvestmentVehicle.ClosingType ==
              vehicleClosingOptionsEnum.CLOSING_SA
            "
          >
            <v-checkbox
              class="dxa-checkbox"
              :readonly="!edit"
              v-model="allocation.SignedTerm"
              color="primary"
              :label="$tc('adhesion_term', 1)"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-if="
              allocation.InvestmentVehicle.ClosingType ==
              vehicleClosingOptionsEnum.CLOSING_SA
            "
          >
            <v-checkbox
              class="dxa-checkbox"
              :readonly="!edit"
              v-model="allocation.SignedNewsletterSubscription"
              color="primary"
              :label="$t('newsletter_subscription')"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-if="
              allocation.InvestmentVehicle.ClosingType ==
              vehicleClosingOptionsEnum.CLOSING_LLC
            "
          >
            <v-checkbox
              class="dxa-checkbox"
              :readonly="!edit"
              v-model="allocation.SignedSubscriptionAgreement"
              color="primary"
              :label="$t('subscription_agreement')"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-divider class="my-2"></v-divider>
        <v-row>
          <v-col cols="12" md="6">
            <p class="small-field-text">{{ $t("deposit") }}:</p>
            <p class="value-text mt-1" v-if="!edit">
              {{
                formatCurrency(allocation.Currency, allocation.DepositedValue)
              }}
            </p>
            <DXATextField
              v-else
              :Text="allocation.DepositedValue"
              :IsCurrency="true"
              :Currency="allocation.Currency"
              @update="
                (value) => {
                  allocation.DepositedValue = value;
                }
              "
            />

            <p class="small-field-text mt-3">{{ $t("documents_date") }}:</p>
            <div
              v-if="
                allocation.InvestmentVehicle.ClosingType ==
                vehicleClosingOptionsEnum.CLOSING_SA
              "
              style="display: flex; align-items: center"
              class="mt-3"
            >
              <p class="document-name">{{ $tc("adhesion_term", 1) }}:</p>
              <p class="document-date" v-if="!edit">
                {{ gs.convert_date(allocation.TermSend, false) }}
              </p>
              <DXATextField
                v-else
                class="ml-2"
                :Text="dateTerm"
                :IsDate="true"
                :ErrorText="errorTerm"
                @update="
                  (value) => {
                    dateTerm = value;
                    var returnValues = check_date(dateTerm);
                    allocation.TermSend = returnValues[0];
                    errorTerm = returnValues[1];
                  }
                "
              />
            </div>
            <div
              v-if="
                allocation.InvestmentVehicle.ClosingType ==
                vehicleClosingOptionsEnum.CLOSING_SA
              "
              style="display: flex; align-items: center"
              class="mt-3"
            >
              <p class="document-name">{{ $t("newsletter_subscription") }}:</p>
              <p class="document-date" v-if="!edit">
                {{
                  gs.convert_date(allocation.NewsletterSubscriptionSend, false)
                }}
              </p>
              <DXATextField
                v-else
                class="ml-2"
                :Text="dateNewsletter"
                :IsDate="true"
                :ErrorText="errorNewsletter"
                @update="
                  (value) => {
                    dateNewsletter = value;
                    var returnValues = check_date(dateNewsletter);
                    allocation.NewsletterSubscriptionSend = returnValues[0];
                    errorNewsletter = returnValues[1];
                  }
                "
              />
            </div>
            <div
              v-if="
                allocation.InvestmentVehicle.ClosingType ==
                vehicleClosingOptionsEnum.CLOSING_LLC
              "
              style="display: flex; align-items: center"
              class="mt-3"
            >
              <p class="document-name">{{ $t("subscription_agreement") }}:</p>
              <p class="document-date" v-if="!edit">
                {{
                  gs.convert_date(allocation.SubscriptionAgreementSend, false)
                }}
              </p>
              <DXATextField
                v-else
                class="ml-2"
                :Text="dateAgreement"
                :IsDate="true"
                :ErrorText="errorNewsletter"
                @update="
                  (value) => {
                    dateAgreement = value;
                    var returnValues = check_date(dateAgreement);
                    allocation.SubscriptionAgreementSend = returnValues[0];
                    errorNewsletter = returnValues[1];
                  }
                "
              />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-col>
              <div style="display: flex; align-items: center" class="mt-3">
                <p class="document-name">{{ $t("deposited_date") }}:</p>
                <p class="document-date" v-if="!edit">
                  {{ gs.convert_date(allocation.DepositedDate, false) }}
                </p>
                <DXATextField
                  v-else
                  class="ml-2"
                  :Text="depositedDate"
                  :IsDate="true"
                  :ErrorText="errorDepositedDate"
                  @update="
                    (value) => {
                      depositedDate = value;
                      var returnValues = check_date(depositedDate);
                      allocation.DepositedDate = returnValues[0];
                      errorDepositedDate = returnValues[1];
                    }
                  "
                />
              </div>
            </v-col>
            <v-col>
              <div v-if="!edit">
                <p class="small-field-text mt-3">{{ $t("obs") }}</p>
                <div
                  style="
                    background: #e9e9e9;
                    border-radius: 5px;
                    height: 140px;
                    overflow-y: auto;
                  "
                >
                  <p class="observations-text pa-3">
                    {{ allocation.Observations }}
                  </p>
                </div>
              </div>
              <DXATextField
                v-else
                :Field="$t('obs')"
                :Text="allocation.Observations"
                :FullHeight="true"
                @update="
                  (value) => {
                    allocation.Observations = value;
                  }
                "
              />
            </v-col>
          </v-col>
        </v-row>
        <v-divider class="mt-2"></v-divider>

        <div style="display: flex; align-items: center" class="mt-2">
          <p class="small-field-text">{{ $t("last_follow_up_date") }}:</p>
          <p class="follow-up-date" v-if="!edit">
            {{ gs.convert_date(allocation.LastFollowUp, false) }}
          </p>
          <DXATextField
            v-else
            class="ml-2"
            :IsDate="true"
            :Text="dateFollowUp"
            :ErrorText="errorFollowUp"
            @update="
              (value) => {
                dateFollowUp = value;
                var returnValues = check_date(dateFollowUp);
                allocation.LastFollowUp = returnValues[0];
                errorFollowUp = returnValues[1];
              }
            "
          />
        </div>
      </div>
      <v-card-actions
        style="display: flex; justify-content: center"
        class="mt-4"
      >
        <v-btn
          v-if="!edit"
          class="dxa-btn-secondary"
          min-width="100"
          width="50%"
          @click="$emit('close')"
        >
          {{ $t("close") }}
        </v-btn>
        <v-row v-else>
          <v-col cols="6" md="3" class="pa-2">
            <v-btn
              class="dxa-btn-secondary mx-0"
              width="100%"
              @click="cancel_click()"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="6" md="3" class="pa-2">
            <v-btn
              v-if="!allocation.Finalized"
              class="dxa-btn-secondary mx-0"
              width="100%"
              :loading="loading"
              @click="submit(false)"
            >
              {{ $t("save") }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" class="pa-2">
            <v-btn
              class="dxa-btn-primary mx-0"
              width="100%"
              :disabled="finalize_btn_disabled()"
              :loading="loading"
              @click="
                allocation.Finalized
                  ? submit(true)
                  : (allocation.Finalized = true)
              "
              >{{ $t("finalize") }}</v-btn
            >
            <p
              v-if="stocks.length == 0"
              style="
                font-style: italic;
                color: red;
                line-height: 1.5;
                font-size: 12px;
              "
              class="mb-0"
            >
              *{{ $t("have_no_stocks") }}
            </p>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>
<style scoped>
::-webkit-scrollbar {
  display: none;
}
.observations-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
}
.follow-up-date {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  margin-left: 4px;
  color: #9c9c9c;
  margin-bottom: 0px !important;
}
.document-date {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  margin-left: 4px;
  margin-bottom: 0px !important;
}
.document-name {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  color: var(--primary);
  margin-bottom: 0px !important;
}
.value-text {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: var(--primary);
  margin-bottom: 0px !important;
}
.small-field-text {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  margin-bottom: 0px !important;
}
.dxa-checkbox input {
  border: #1c4cbd;
  background-color: #1c4cbd;
}
.dxa-checkbox input:checked {
  border: #1c4cbd;
  background-color: #1c4cbd;
}
.dxa-checkbox input:checked {
  border: #1c4cbd;
  background-color: #1c4cbd;
}
</style>
<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import DXATextField from "@/components/dxa/TextField/DxaTextField";
import moment from "moment";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { vehicleClosingOptionsEnum } from "@/shared/enums/VehicleEnum";
export default {
  name: "ManageAllocationModal",
  components: { DXATextField },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    allocation: null,
    edit: false,
    dateTerm: null,
    dateNewsletter: null,
    dateAgreement: null,
    depositedDate: null,
    dateFollowUp: null,
    errorTerm: null,
    errorNewsletter: null,
    errorFollowUp: null,
    errorStocks: null,
    stocks: [],
    formatCurrency: formatCurrency,
    vehicleClosingOptionsEnum,
  }),
  props: {
    Allocation: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  async created() {
    this.reload_allocation();
    await this.load_company_stocks();
  },
  methods: {
    changeAllocationStockQuantity() {
      this.allocation.StockQuantity = Math.ceil(
        this.allocation.Value / this.allocation.Stock.value
      );
    },
    validate_stock_quantity() {
      this.errorStocks = null;
      if (
        this.allocation.StockQuantity == null ||
        this.allocation.StockQuantity == ""
      ) {
        this.allocation.StockQuantity = 0;
        return;
      }
      if (!Number.isInteger(parseInt(this.allocation.StockQuantity))) {
        this.errorStocks = this.$t("integer_only");
        return;
      }
      if (
        this.allocation.StockQuantity * this.allocation.Stock.Value >
        this.allocation.DepositedValue
      ) {
        this.errorStocks = this.$t("value_exceeded_deposited");
      }
      this.allocation.StockQuantity = parseInt(this.allocation.StockQuantity);
    },
    async load_company_stocks() {
      let roundId = this.allocation.InvestmentRoundId
        ? this.allocation.InvestmentRoundId
        : 0;
      await this.apiService
        .getRequest(
          `investmentvehicle/vehiclestockvalue/${this.allocation.InvestmentVehicleId}?currency=${this.allocation.Currency}&investmentRoundId=${roundId}`
        )
        .then((resp) => {
          this.stocks = resp.content.vehicleStockTypes;
        })
        .catch((error) => {});
    },
    reload_allocation() {
      this.errorFollowUp = null;
      this.errorTerm = null;
      this.errorNewsletter = null;
      this.allocation = JSON.parse(JSON.stringify(this.Allocation));
      if (this.allocation.LastFollowUp) {
        this.dateFollowUp = moment(this.allocation.LastFollowUp).format(
          "DD/MM/YYYY"
        );
      }
      if (this.allocation.NewsletterSubscriptionSend) {
        this.dateNewsletter = moment(
          this.allocation.NewsletterSubscriptionSend
        ).format("DD/MM/YYYY");
      }
      if (this.allocation.SubscriptionAgreementSend) {
        this.dateAgreement = moment(
          this.allocation.SubscriptionAgreementSend
        ).format("DD/MM/YYYY");
      }

      if (this.allocation.TermSend) {
        this.dateTerm = moment(this.allocation.TermSend).format("DD/MM/YYYY");
      }
      if (this.allocation.DepositedDate) {
        this.depositedDate = moment(this.allocation.DepositedDate).format(
          "DD/MM/YYYY"
        );
      }
    },
    finalize_btn_disabled() {
      return (
        (this.allocation.Finalized &&
          (this.allocation.StockQuantity == 0 || this.errorStocks)) ||
        (!this.allocation.Finalized &&
          (this.stocks.length == 0 ||
            !this.allocation.DepositedDate ||
            !this.allocation.LastFollowUp ||
            !this.allocation.SignedInvestmentProxy ||
            (this.allocation.InvestmentVehicle.ClosingType ==
              this.vehicleClosingOptionsEnum.CLOSING_SA &&
              (!this.allocation.NewsletterSubscriptionSend ||
                !this.allocation.SignedNewsletterSubscription ||
                !this.allocation.TermSend ||
                !this.allocation.SignedTerm)) ||
            (this.allocation.InvestmentVehicle.ClosingType ==
              this.vehicleClosingOptionsEnum.CLOSING_LLC &&
              (!this.allocation.SubscriptionAgreementSend ||
                !this.allocation.SignedSubscriptionAgreement)) ||
            this.allocation.DepositedValue < this.allocation.Value))
      );
    },
    valid_date(date) {
      return (
        (date &&
          parseInt(date.split("/")[2]) > 1600 &&
          moment([
            parseInt(date.split("/")[2]),
            parseInt(date.split("/")[1]) - 1,
            parseInt(date.split("/")[0]),
          ]).isValid()) ||
        this.$t("valid_date")
      );
    },
    cancel_click() {
      this.reload_allocation();
      this.edit = false;
    },
    check_date(date) {
      if (!date) {
        return [null, null];
      }
      var result = this.valid_date(date);
      if (result != true) {
        return [null, result];
      } else {
        return [this.gs.parseDate(date), null];
      }
    },
    async submit(finalize) {
      if (
        this.loading ||
        this.errorTerm ||
        this.errorNewsletter ||
        this.errorFollowUp
      ) {
        return;
      }

      this.loading = true;
      this.allocation.Finalized = finalize;

      await this.apiService.postRequest(
        "companyinterest/follow-up-edit",
        this.allocation
      );
      this.loading = false;
      this.$emit("update", this.allocation);
    },
  },
};
</script>
