<template>
  <div>
    <v-form
      @submit.stop.prevent="get_allocations_by_page()"
      ref="form"
      lazy-validation
      class="d-flex"
    >
      <v-text-field
        :label="$t('search')"
        append-icon="mdi-magnify"
        @click:append="get_allocations_by_page"
        v-model="searchFilter"
        filled
        dense
        hide-details
        class="mx-auto"
        style="width: 100%; max-width: 500px"
      >
      </v-text-field>

      <v-text-field
        filled
        hide-details
        style="max-width: 240px"
        :label="$tc('range_date', 1)"
        class="mx-auto"
        v-model="initialDate"
        type="date"
      ></v-text-field>

      <v-text-field
        style="max-width: 240px"
        hide-details
        filled
        :label="$tc('range_date', 2)"
        v-model="finalDate"
        class="mx-auto"
        type="date"
      ></v-text-field>

      <v-btn
        @click="get_allocations_by_page"
        class="dxa-btn-icon-success"
        style="background-color: transparent"
        >{{ $t("apply") }}</v-btn
      >
      <v-btn
        @click="cleanFilter"
        style="background-color: transparent"
        class="dxa-btn-icon-success"
        ><v-icon>mdi-trash-can-outline</v-icon>{{ $t("clean") }}</v-btn
      >
    </v-form>
    <v-row justify="center" class="mt-4" v-if="loading">
      <v-progress-circular
        indeterminate
        size="20"
        color="primary"
      ></v-progress-circular>
    </v-row>
    <div v-else-if="allocations.length == 0">
      <p class="not-found text-center my-10">
        {{ $t("allocation_not_found") }}
      </p>
    </div>
    <div v-else>
      <v-simple-table v-if="!gs.isMobile()">
        <thead>
          <tr>
            <th class="pl-6">{{ $tc("investor", 1) }}</th>
            <th class="text-center">{{ $t("date") }}</th>
            <th class="pl-6">{{ $t("value") }}</th>
            <th class="pl-6">{{ $t("pendencies") }}</th>
            <th class="text-center">{{ $t("last_follow_up_date") }}</th>
            <th class="text-center">{{ $t("actions") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(n, index) in allocations" :key="index">
            <td>{{ gs.show_full_name(n.User) }}</td>
            <td class="text-center">
              {{ gs.convert_date(n.Timestamp, false) }}
            </td>
            <td>{{ formatCurrency(n.Currency, n.Value) }}</td>
            <td>
              <p
                class="my-1"
                style="font-size: 13px"
                v-for="(p, i) in get_pendencies(n)"
                :key="i"
              >
                {{ p }}
              </p>
            </td>
            <td class="text-center">
              {{ gs.convert_date(n.LastFollowUp, false) }}
            </td>
            <td class="text-center" v-if="Status == 0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="dxa-btn-icon-success"
                    style="margin-right: 12px !important"
                    v-bind="attrs"
                    v-on="on"
                    @click="manage_click(n, 0)"
                  >
                    <v-icon small>mdi-check</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("confirm") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="dxa-btn-icon-success"
                    style="margin-right: 12px !important"
                    v-bind="attrs"
                    v-on="on"
                    @click="manage_click(n, 1)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("edit") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="dxa-btn-icon-danger"
                    v-bind="attrs"
                    v-on="on"
                    @click="manage_click(n, 2)"
                  >
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("decline") }}</span>
              </v-tooltip>
            </td>
            <td class="text-center" v-else>
              <v-btn
                class="dxa-btn-secondary"
                style="margin-right: 12px !important"
                @click="manage_click(n, null)"
              >
                <v-icon class="mr-1">mdi-text-box-outline</v-icon>
                <span>{{ $t("detail") }}</span>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div v-else>
        <p class="my-2 text-center" style="color: #9c9c9c">
          {{ $t("click_edit") }}
        </p>
        <div
          v-for="(n, index) in allocations"
          :key="index"
          class="mobile-request"
        >
          <a style="display: flex" @click="manage_click(n, null)">
            <v-col
              cols="6"
              style="display: flex; align-items: center; justify-content: start"
            >
              <div>
                <p
                  style="
                    color: var(--dark);
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.15px;
                    margin-bottom: 2px;
                  "
                >
                  {{ gs.convert_date(n.Timestamp, true) }}
                </p>
                <p
                  style="
                    color: var(--dark);
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.15px;
                    margin-bottom: 2px;
                  "
                >
                  {{ n.Company.Name }}
                </p>
                <p
                  style="
                    color: var(--dark);
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.15px;
                  "
                >
                  {{ gs.show_full_name(n.User) }}
                </p>
              </div>
            </v-col>
            <v-col
              cols="6"
              style="display: flex; justify-content: end; align-items: center"
            >
              <p
                style="
                  font-style: normal;
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 20px;
                  text-align: center;
                  letter-spacing: 0.15px;
                "
              >
                {{ formatCurrency(n.Currency, n.Value) }}
              </p>
            </v-col>
          </a>
        </div>
      </div>
      <v-pagination
        color="primary"
        @input="changePage"
        :length="total_pages"
        v-model="page"
      >
      </v-pagination>
    </div>
    <v-dialog v-model="manageModal" v-if="manageModal && !gs.isMobile()">
      <ManageAllocationModal
        :Allocation="allocation"
        :Option="manageOption"
        @close="reset_modal"
        @update="update_allocation"
      />
    </v-dialog>
    <v-bottom-sheet
      v-model="manageModal"
      v-if="manageModal && gs.isMobile()"
      scrollable
    >
      <ManageAllocationModal
        :Allocation="allocation"
        :Option="manageOption"
        @close="reset_modal"
        @update="update_allocation"
      />
    </v-bottom-sheet>
  </div>
</template>
<style scoped>
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
.mobile-request {
  height: 72px;
  width: 100%;
  border: 0.5px solid #9c9c9c;
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 6px;
}
.not-found {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
}
</style>
<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ManageAllocationModal from "../../form/ManageAllocationModal.vue";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { vehicleNationality } from "@/shared/enums/VehicleEnum";

export default {
  name: "CompanyAllocationsTable",

  components: { ManageAllocationModal },

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    page: 1,
    total_pages: 1,
    valid: true,
    lazy: false,
    companyId: null,
    searchFilter: null,
    manageModal: false,
    manageOption: 0,
    allocation: null,
    allocations: [],
    initialDate: null,
    finalDate: null,
    formatCurrency: formatCurrency,
    vehicleNationality,
  }),
  async created() {
    this.companyId = parseFloat(this.$route.params.companyId);
    this.get_allocations_by_page();
  },
  props: {
    Tab: Number,
  },
  watch: {
    Tab() {
      this.get_allocations_by_page();
    },
  },
  computed: {},
  methods: {
    cleanFilter() {
      this.searchFilter = null;
      this.initialDate = null;
      this.finalDate = null;
      this.get_allocations_by_page();
    },
    get_pendencies(a) {
      var pendencies = [];
      if (!a.SignedInvestmentProxy) {
        pendencies.push(`- ${this.$tc("cta_investment_proxy_signed", 1)}`);
      }
      if (
        a.InvestmentVehicle.VehicleNationality == vehicleNationality.NATIONAL &&
        !a.SignedTerm
      ) {
        pendencies.push(`- ${this.$tc("cta_adhesion_term_signed", 1)}`);
      }
      if (
        a.InvestmentVehicle.VehicleNationality == vehicleNationality.NATIONAL &&
        !a.SignedNewsletterSubscription
      ) {
        pendencies.push(
          `- ${this.$tc("cta_newsletter_subscriptions_signed", 1)}`
        );
      }
      if (
        a.InvestmentVehicle.VehicleNationality ==
          vehicleNationality.INTERNATIONAL &&
        !a.SignedSubscriptionAgreement
      ) {
        pendencies.push(
          `- ${this.$tc("cta_subscription_agreement_signed", 1)}`
        );
      }
      if (a.DepositedValue < a.Value) {
        pendencies.push(`- ${this.$t("cta_deposited_value")}`);
      }
      return pendencies;
    },
    update_allocation(allocation) {
      var index = this.allocations.indexOf(this.allocation);
      if (allocation.Finalized) {
        this.allocations.splice(index, 1);
      } else {
        this.allocations[index] = allocation;
      }
      this.$emit("update");
      this.reset_modal();
    },
    reset_modal() {
      this.allocation = null;
      this.manageModal = false;
    },
    manage_click(allocation, option) {
      this.allocation = allocation;
      this.manageOption = option;
      this.manageModal = true;
    },
    get_allocations_by_page() {
      this.loading = true;
      var page = this.page;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page);
      }
      var params = {
        Page: page,
        Status: this.Status,
        NameFilter: this.searchFilter,
        CompanyId: this.companyId,
        Finalized: this.Tab == 1,
        InitialDate: this.initialDate,
        FinalDate: this.finalDate,
      };
      this.apiService
        .postRequest("companyinterest/company/filter", params)
        .then((resp) => {
          var json = JSON.parse(resp.message);
          this.page = json.Pager.CurrentPage;
          this.total_pages = json.Pager.TotalPages;
          this.allocations = json.Interests;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    changePage() {
      this.$router.replace({
        query: { page: this.page, tab: this.Status + 1 },
      });
      this.get_allocations_by_page();
    },
  },
};
</script>
