<template>
  <v-container fluid tag="section">
    <p
      style="
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 0px;
      "
    >
      {{ $t("allocation_requests") }}
    </p>
    <span
      style="
        font-weight: 300;
        font-size: 15px;
        line-height: 35px;
        color: #9c9c9c;
      "
      >{{ $t("allocation_requests_desc") }}</span
    >
    <div class="dxa-expand-container mt-2">
      <a
        @click="summaryExpanded = !summaryExpanded"
        style="display: flex; justify-content: end; align-items: center"
      >
        <p class="mb-0" style="color: #9c9c9c">
          {{ $t(summaryExpanded ? "close_summary" : "open_summary") }}
        </p>
        <v-icon color="primary" class="ml-2">{{
          summaryExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </a>
      <v-expand-transition>
        <div v-if="summaryExpanded">
          <v-row justify="center" v-if="summaryLoading">
            <v-progress-circular
              indeterminate
              size="15"
              color="primary"
            ></v-progress-circular>
          </v-row>
          <v-row align="center" justify="space-between" v-else>
            <v-col cols="12" sm="12" md="6">
              <div class="info-box">
                <div style="width: 100%" class="pa-3">
                  <v-row no-gutters>
                    <p class="deposited-text">{{ $t("total_deposited") }}</p>
                  </v-row>
                  <v-row align="center" no-gutters>
                    <div class="summary-total-deposited">
                      <div v-for="(item, i) in summary.totalDeposited" :key="i">
                        <div style="display: flex; align-items: center">
                          <p class="value-text">
                            {{
                              formatToCurrency(
                                item.currency,
                                item.totalDeposited
                              )
                            }}
                          </p>
                          <p class="pct-text ml-4">
                            {{ item.totalDepositedPercentage.toFixed(2) }}%
                          </p>
                        </div>
                      </div>
                    </div>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :loading="followUpReportDownloading"
                          @click="download_follow_up_report()"
                          class="dxa-btn-secondary ma-0"
                          :class="{ 'download-btn-mobile': gs.isMobile() }"
                          ><v-icon class="mr-1"
                            >mdi-file-download-outline</v-icon
                          >{{ $t("summary") }}</v-btn
                        >
                      </template>
                      <span>{{ $t("download") }}</span>
                    </v-tooltip>
                  </v-row>
                </div>
              </div>
            </v-col>
            <v-col
              v-for="(info, i) in signedInfo"
              :key="i"
              cols="12"
              sm="6"
              md="2"
            >
              <div class="info-box">
                <div>
                  <p class="signed-info-text">{{ info.infoText }}</p>
                  <p class="signed-quantity-text">
                    {{ summary[info.objectLabel].signed }}
                  </p>
                  <p class="signed-pending-text">
                    {{
                      $t("value_pending", {
                        value: summary[info.objectLabel].pending,
                      })
                    }}
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
    </div>
    <v-tabs
      show-arrows
      grow
      centered
      background-color="transparent"
      v-model="tab"
      class="dxa-tabs mt-4"
    >
      <v-tab
        v-for="(item, index) in tab_options"
        :key="index"
        @click="index_changed(item.index)"
      >
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <div id="interest-table" class="dxa-card mt-3">
      <AllocationsTable :Tab="tab" @update="get_follow_up_summary()" />
    </div>
  </v-container>
</template>
<style scoped>
.pct-text {
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #7833ff;
  margin-bottom: 0px !important;
}
.value-text {
  font-weight: bold;
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--primary);
  margin-bottom: 0px !important;
}
.signed-pending-text {
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  margin-bottom: 0px !important;
}
.signed-quantity-text {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--primary);
  margin-bottom: 0px !important;
}
.signed-info-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  margin-bottom: 0px !important;
}
.deposited-text {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  margin-bottom: 0px !important;
}
.info-box {
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 12px;
  height: 132px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.download-btn-mobile {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 6px !important;
}

.summary-total-deposited {
  display: grid;
  gap: 16px;
}
</style>
<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import AllocationsTable from "./children/Table.vue";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
export default {
  name: "AllocationsFollowUp",

  components: { AllocationsTable },

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    summaryExpanded: true,
    summary: {},
    summaryLoading: false,
    followUpReportDownloading: false,
    tab: 0,
    formatToCurrency: formatCurrency,
  }),
  async created() {
    await this.get_follow_up_summary();
  },
  computed: {
    signedInfo() {
      return [
        {
          infoText: this.$tc("investment_proxy_signed", 2),
          objectLabel: "investmentProxy",
        },
        {
          infoText: this.$tc("signed_newsletter_subscriptions", 2),
          objectLabel: "newsletterSubscription",
        },
        {
          infoText: this.$tc("adhesion_term", 2),
          objectLabel: "adhesionTerm",
        },
      ];
    },
    tab_options() {
      return [
        { title: this.$tc("pending", 2), index: 0 },
        { title: this.$tc("finalized", 2), index: 1 },
      ];
    },
  },
  methods: {
    download_follow_up_report() {
      this.followUpReportDownloading = true;
      this.apiService
        .getRequest(
          `companyinterest/generate-follow-up-report/${this.$route.params.companyId}`
        )
        .then((resp) => {
          resp = "data:application/xlsx;base64," + resp.message;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute("download", `Relatório de alocações.xlsx`);
          document.body.appendChild(link);
          link.click();
          this.followUpReportDownloading = false;
        })
        .catch((error) => {
          this.followUpReportDownloading = false;
        });
    },
    async get_follow_up_summary() {
      this.summaryLoading = true;
      await this.apiService
        .getRequest(
          `companyinterest/follow-up-summary/${this.$route.params.companyId}`
        )
        .then((resp) => {
          this.summary = resp.content;
        })
        .catch((error) => {});
      this.summaryLoading = false;
    },
    index_changed(index) {
      this.$router.replace({ query: { tab: index + 1 } });
    },
  },
};
</script>
